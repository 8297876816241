@import "variables.less";

@media screen and (max-width: 767px) {
    body {
        padding-top: 0;
    }
}

.ant-collapse-item+ant-collapse-item {
    padding-top: 10px;
}

.ant-form-item {
    margin-bottom: 0;
}

.ant-form-item-label {
    height: 28px;
}

.ant-calendar-picker {
    width: 100%;
}

.ant-time-picker {
    width: 100%;
}

.ant-input-number {
    width: 100%;
}

.ant-alert {
    margin: 5px;
}

.ant-month-picker {
    min-width: 160px;
}

.hide {
    display: none;
}

.ant-collapse+button {
    margin-top: 10px;
}

.ant-table {
    border-radius: 4px;
    border: solid 1px @header-border;

    .ant-btn-circle {
        &:not(.ant-btn-danger) {
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .ant-btn {
        &:hover {
            &:not(.ant-btn-danger) {
                border-color: @button-border;
            }
        }
    }

    .ant-btn+.ant-btn {
        margin-left: 5px;
    }

    table {
        font-size: 13px;
        font-weight: 600;
        border-collapse: collapse;
    }
}

.ant-btn {
    &:focus {
        &:not(.ant-btn-danger) {
            border-color: @button-border;
        }
    }
}

.centered-row {
    .ant-table {
        table {
            text-align: center;
        }
    }
}

.ant-table-title {
    background-color: @primary-color;
    font-family: "Chinese Quote";
    font-size: 1.3em;
    font-weight: 700;
    padding: 5px 10px 5px 10px;
    text-transform: uppercase;
}

.ant-table-thead>tr>th+th {
    padding-left: 3px;
    padding-right: 3px;
}

.ant-table-tbody {
    >tr>td+td {
        padding-left: 3px;
        padding-right: 3px;
    }

    >tr>td {
        background-color: @row-primary-color;
    }

    >tr.row-alternate>td {
        background-color: @row-alternate-color;
    }
}

.ant-collapse {
    .ant-collapse-header {
        background-color: @secondary-color;
    }
}

.row-alternate {
    background-color: @row-alternate-color;
}

.row-attention-grabbing-highlight>td {
    color: red;
    color: red;
}

.row-last-used {
    border-top: dashed;
    border-bottom: dashed;
    border-color: @heading-color;
}

.page-title {
    background-color: @primary-color;
    border-radius: 4px 4px 4px 4px;
    color: @font-color-header !important;
    font-size: 1.2em;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    margin-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.page-title>button {
    margin-left: 5px;
}

.page-title>div>button {
    margin-left: 5px;
}

.page-title+div {
    .ant-table {
        border-radius: 0;
    }
}

.page-title~* {
    &:not(.ant-table-wrapper) {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.page-title-addon {
    float: right;
    font-weight: 400;
}

.ant-table-pagination {
    padding-left: 14px;
    padding-right: 14px;
}

.ant-table-placeholder {
    border-radius: 4px;
}

.ant-layout {
    background-color: @content-color;
}

.ant-layout-content {
    background-color: @content-color;
    border-radius: 4px;
    margin-top: 0;
    margin: 14px;
    padding-bottom: 14px;
}

.ant-layout-footer {
    color: #ffffff !important;
}

.ant-menu {
    -webkit-box-shadow: none !important;
    background-color: @layout-body-background !important;
    box-shadow: none !important;
}

.ant-menu-submenu {
    -webkit-box-shadow: none !important;
    background-color: @layout-body-background !important;
    box-shadow: none !important;
}

.ant-menu-item-selected {
    background-color: @primary-color;
    color: @layout-body-background !important;

    a {
        color: @layout-body-background !important;
        font-weight: 700;
        margin-left: -7px;
    }
}

.ant-menu-item {
    display: block !important;

    a {
        color: @layout-body-background;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ant-menu-item>span>a {
    color: @layout-body-background;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-select-dropdown-menu-item {
    overflow: visible;
}

.ant-select-dropdown-menu {
    overflow-x: auto;
}

.ant-select-selector,
.ant-picker,
.ant-input-number {
    margin-bottom: 3px;
}

.top-bar {
    color: @text-color;
    height: 62px;
    overflow: hidden;
    padding-right: 14px;
    padding-top: 12px;
    width: 100%;

    .account {
        line-height: 32px;
        padding-left: 14px;
        text-align: right;

        .ant-avatar {
            margin-left: 5px;
        }

        .userName {
            vertical-align: middle;
        }
    }

    a {
        color: @text-color;
    }

    .environment {
        float: left;
        text-align: right;
        padding-left: 14px;
        line-height: 32px;
        font-size: larger;
        color: @alert-text-color;
        font-weight: bolder;
    }
}

.dd-details-card {
    border-radius: 10px;
    margin: 4px;
    height: 100%;
}

.ant-card-head {
    background-color: @secondary-color !important;
    color: @text-color;
}

.ant-card-body {
    padding: 6px;
}

.primary-card {
    margin-bottom: 16px;
    margin-top: 16px;
}

.ant-card-bordered {
    border-radius: 4px;
}

.column-filter-active {
    color: @primary-color;
}

.grid-filter-inactive {
    background-color: rgba(0, 0, 0, 0) !important;
}

.grid-filter-inactive>span {
    color: @primary-color;
}

.grid-filter-active {
    background-color: @primary-color !important;
}

.grid-filter-active>span {
    color: @btn-primary-color;
}

.divider-thin {
    margin-top: 12px;
    margin-bottom: 12px;
}

.alerts-container {
    margin-bottom: 5px;

    .ant-collapse-header {
        margin: -1px -1px -1px -1px;
        border: solid 1px @header-border;
        border-radius: 4px 4px 0 0 !important;
        color: @text-color;
    }
}

.announcement .ant-alert-message {
    color: red !important;
    font-weight: bolder;
}
