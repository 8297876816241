

.ant-row+.ant-row {
	margin-top: 2px;
}
.ant-row {
	label {
		line-height: 32px;
	}
}
label {
	font-weight: 500;
}
.ant-collapse-content>.ant-collapse-content-box {
	padding: 10px;
}
.ant-row+.ant-collapse {
	margin-top: 10px;
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	font-weight: 400;
	padding: 6px 0 6px 32px;
	.arrow {
		left: 8px;
	}
}
.ant-collapse > .ant-collapse-item:first-of-type > .ant-collapse-header {
    border-radius: 10px 10px 0 0;
}
.ant-collapse > .ant-collapse-item-active:last-child  > .ant-collapse-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.form-label-optional {
    color: #989898;
}
.form-error {
	> input, > div.ant-picker, > div.ant-input-number input {
		border: 1px solid red;
	}
	.ant-select-selector {
		border: 1px solid red !important;
	}
}
.form-error+Button {
	margin-top: 10px;
}
.ant-list-sm {
	.ant-list-item {
		padding-bottom: 1px;
		padding-top: 1px;
	}
}
.ant-list-pagination {
	margin-top: 6px;
}
.ant-select-selection__clear {
	color: rgba(255, 0, 0, 0.5);
}
.form-panel-error {
	border-color: rgba(255, 0, 0, 0.3);
}
.form-panel-error>.ant-collapse-header {
	color: rgba(255, 0, 0, 1) !important;
	font-weight: bolder !important;
}
.form-error-label {
	color: #ff0000;
}
.form-collapse+.form-collapse {
	margin-top: 4px;
}
.ant-select {
	max-width: 100%;
	min-width: 100%;
}
.ant-input {
	line-height: normal;
}

