@import '../../variables.less';

// .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
//     background-color: @content-color !important;
//     border-bottom-color: @content-color !important;
// }

// .ant-tabs-card {
//     .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {    
//         background-color: #fff !important;
//     }
//     .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
//         background-color: @content-color !important;
//         border-bottom-color: @content-color !important;
//     }
// }
.ant-tabs-nav {
    .ant-tabs-tab-active {
        background-color: @content-color !important;
        border-bottom-color: @content-color !important;
    }
    .ant-tabs-tab {
        &:not(.ant-tabs-tab-active) {
            background-color: #fff !important;
        }
    }
}
