.report-templates-container {
    margin: 15px 0 15px 0 !important;

    .ant-collapse-header {
        margin: -1px -1px -1px -1px;
        border-radius: 5px 5px 5px 5px !important;
    }
}

.report-register-not-selected {
    font-size: 14px;
    font-weight: 600;
}
